//
//
//
//
//
//
//
//

export default {
  props: {
    rating: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 10,
    },
  },
  methods: {
    getStarIcon(index) {
      const filledStars = Math.ceil(this.rating / 2);
      if (index * 2 <= this.rating) {
        return ['fas', 'star'];
      } else if (index === filledStars && this.rating % 2 !== 0) {
        return ['fas', 'star-half-stroke'];
      } else {
        return ['far', 'star'];
      }
    },
  },
};
