//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import camItem from '~/components/content/cam/cam_new_card.vue';
import dummyItem from '~/components/content/dummy/video_dummy.vue';

export default {
  async fetch() {
    await this.$store.dispatch('camStore/getCams', this.camsQuery)
  },
  components: {
    camItem,
    dummyItem,
  },
  computed: {
    ...mapState({
      cams: ({ camStore: { cams } }) => cams,
      camsQuery: ({ camStore: {camsQuery} }) => camsQuery,
      loading: ({ camStore: {loading} }) => loading
    }),
  },
};
