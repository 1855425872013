//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  export default {
    components: {
      videoItem: () => import('~/components/content/video/video.vue'),
    },
    props: {
      loadingState: {
        type: String,
      default: '',
      },
      sliderItems: {
        type: Array,
      default: () => [],
      },
      sliderId: {
        type: String,
      default: '',
      },
      itemType: {
        type: String,
      default: ''
      },
      itemId: {
        type: String,
      default: ''
      },
      coverProperty: {
        type: String,
      default: 'images'
      }
    },
    data() {
      return {
        backEnd: true,
        forwardEnd: false,
      };
    },
    methods: {
      handleBackClick() {
        this.scrollBack(this.sliderId);
      },
      handleForwardClick() {
        this.scrollForward(this.sliderId);
      },
      chooseCoverProperty(resource) {
        if (this.coverProperty === 'images') {
          return resource.images && resource.images.data.cover && resource.images.data.cover.length  ? resource.images.data.cover[0].sizes : '';
        } else if (this.coverProperty === 'covers') {
          return resource.covers && resource.covers[0] ? resource.covers[0] : '';
        } else {
        return ''; // You can handle other cases as needed
      }
    },
  },
};
