//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    hoverTitle: {
      type: String,
    default: '',
    },
    textTitle: {
      type: String,
      required: true,
    },
    linkVisibility: {
      type: Boolean,
    default: false,
    },
    linkPath: {
      type: String,
    default: '',
    },
    linkText: {
      type: String,
    default: '',
    },
    showFromTablet: {
      type: Boolean,
    default: false,
    },
    unlimitedLogo: {
      type: Boolean,
    default: false,
    },
  },
};
