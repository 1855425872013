import { render, staticRenderFns } from "./cam_new_card.vue?vue&type=template&id=afcf6d56&scoped=true"
import script from "./cam_new_card.vue?vue&type=script&lang=js"
export * from "./cam_new_card.vue?vue&type=script&lang=js"
import style0 from "./cam_new_card.vue?vue&type=style&index=0&id=afcf6d56&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afcf6d56",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RatingStarsNew: require('/home/forge/secretcircle.com/components/ratingStarsNew.vue').default})
